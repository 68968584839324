import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import { Context, useGlobalState } from './store'
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import validators from './common/validators';
import Routes from './Routes';

const browserHistory = createBrowserHistory();

// @ts-ignore
// Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
//   draw: chartjs.draw
// });

validate.validators = {
  ...validate.validators,
  ...validators
};

const App = () => {
  const { state, mutator, selector } = useGlobalState();

  return (
    <Context.Provider value={{ state, mutator, selector }}>
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </ThemeProvider>
    </Context.Provider>
  )
};

export default App;
