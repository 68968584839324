import clsx from 'clsx';
import { Trigger } from "types";
import { makeStyles } from '@material-ui/styles';
import {
  Card,
} from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

interface TriggersTableProps {
  className?: string;
  triggers: Trigger[];
  deviceId: string;
}

const TriggersTable = (props: TriggersTableProps) => {
  const { className, deviceId, triggers, ...rest } = props;
  const classes: any = useStyles();
  // const history = useHistory();
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [page, setPage] = useState(0);

  // const handleClick = (event: any, triggerId: string) => {
  //   history.push(`/device/${deviceId}/trigger/${triggerId}`);
  // };
  //
  // const handleTicketClick = (event: any, triggerId: string, ticketId: string) => {
  //   history.push(`/device/${deviceId}/trigger/${triggerId}/ticket/${ticketId}`);
  // };

  // const getTicketComponent = (triggerId: string, ticketId: string) => {
  //   if(ticketId) {
  //     return <IconButton
  //       onClick={(event) => handleTicketClick(event, triggerId, ticketId)}
  //     >
  //       <NotificationsActiveIcon />
  //     </IconButton>
  //   }
  //
  //   return <p>Not Triggering :)</p>;
  // };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      {
    //   <CardContent className={classes.content}>
    //     <PerfectScrollbar>
    //       <div className={classes.inner}>
    //         <Table>
    //           <TableHead>
    //             <TableRow>
    //               <TableCell />
    //               <TableCell />
    //               <TableCell>Comparator</TableCell>
    //               <TableCell>Threshold</TableCell>
    //               <TableCell>Count</TableCell>
    //               <TableCell>Period</TableCell>
    //               <TableCell>Message</TableCell>
    //               <TableCell>Status</TableCell>
    //             </TableRow>
    //           </TableHead>
    //           <TableBody>
    //             {triggers.slice(0, rowsPerPage).sort((a, b) => a.created-b.created).map(trigger => (
    //               <TableRow
    //                 className={classes.tableRow}
    //                 hover
    //                 key={trigger.id}
    //               >
    //                 <TableCell>
    //                   {getTicketComponent(trigger.id, trigger.ticketId)}
    //                 </TableCell>
    //                 <TableCell>
    //                   <IconButton
    //                     onClick={(event) => handleClick(event, trigger.id)}
    //                   >
    //                     <EditIcon />
    //                   </IconButton>
    //                 </TableCell>
    //                 <TableCell>{trigger.comparator}</TableCell>
    //                 <TableCell>{trigger.threshold}</TableCell>
    //                 <TableCell>{trigger.count}</TableCell>
    //                 <TableCell>{trigger.period}</TableCell>
    //                 <TableCell>{trigger.status}</TableCell>
    //               </TableRow>
    //             ))}
    //           </TableBody>
    //         </Table>
    //       </div>
    //     </PerfectScrollbar>
    //   </CardContent>
    //   <CardActions className={classes.actions}>
    //     <TablePagination
    //       component="div"
    //       count={triggers.length}
    //       onChangePage={handlePageChange}
    //       onChangeRowsPerPage={handleRowsPerPageChange}
    //       page={page}
    //       rowsPerPage={rowsPerPage}
    //       rowsPerPageOptions={[5, 10, 25]}
    //     />
    //   </CardActions>
    }
    </Card>
  );
};

export default TriggersTable;
