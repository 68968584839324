import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {Device, DeviceState} from 'types'
import { getDevices } from 'store/api';
import moment from "moment/moment";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory, useParams } from "react-router-dom";
import BackButton from "components/BackButton";
import SignalWifi4BarLockIcon from '@material-ui/icons/SignalWifi4BarLock';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';

const useStyles = makeStyles((theme:any) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  online: {
    color: "#75db75",
    marginLeft: "10px"
  },
  offline: {
    color: "#e5e5e5",
    marginLeft: "10px"
  }
}));

interface DevicesToolbarProps {
  orgId:string;
}

const DevicesToolbar = (props:DevicesToolbarProps) => {
  const { orgId } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleCreateDevice = (event:any) => {
    history.push(`/admin/org/${orgId}/devices/create`);
  };

  return (
    <div className={classes.root}>
      <Button
        color="primary"
        variant="contained"
        onClick={() => handleCreateDevice(orgId)}
      >
        Create Device
      </Button>
    </div>
  );
};

interface DevicesTableProps {
  orgId:string;
  devices:Array<Device>;
}

const DevicesTable = (props:DevicesTableProps) => {
  const { orgId, devices } = props;
  const history = useHistory();

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event:any, page:any) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event:any) => {
    setRowsPerPage(event.target.value);
  };

  const handleClick = (event:any, deviceId:string) => {
    history.push(`/admin/org/${orgId}/devices/${deviceId}`);
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Provision Key</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Last Modified</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices.slice(0, rowsPerPage).map(device => {
                const deviceState = (device.deviceState && device.deviceState.reported) ?? {} as DeviceState;
                const isConnected = deviceState.connected;

                return (
                  <TableRow hover key={device.id} onClick={(event) => handleClick(event, device.id)}>
                    <TableCell>
                      { isConnected ? <SignalWifi4BarLockIcon className={classes.online} /> : <SignalWifiOffIcon className={classes.offline} /> }
                    </TableCell>
                    <TableCell>{device.name}</TableCell>
                    <TableCell>{device.provisionKey}</TableCell>
                    <TableCell>
                      {moment(device.created).format('MM/DD/YYYY')}
                    </TableCell>
                    <TableCell>
                      {moment(device.modified).format('MM/DD/YYYY')}
                    </TableCell>
                  </TableRow>
                )}
              )}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={devices.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

interface DeviceListParams {
  orgId: string;
}

const DeviceList = () => {
  const classes = useStyles();
  const [devices, setDevices] = useState<Array<Device>>([]);

  const { orgId } = useParams<DeviceListParams>();

  useEffect(() => {
    getDevices(orgId).then(setDevices);
  }, []);

  return (
    <div className={classes.root}>
      <BackButton />
      <DevicesToolbar orgId={orgId} />
      <div className={classes.content}>
        <DevicesTable orgId={orgId} devices={devices} />
      </div>
    </div>
  );
};

export default DeviceList;
