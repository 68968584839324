import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import { ApiError, createOrg } from 'store/api';
import { useFormState, useInputRequired } from 'hooks';
import BackButton from "components/BackButton";

const useStyles = makeStyles((theme:any) => ({
  root: {
    padding: theme.spacing(3)
  },
  grid: {
    height: '100%'
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex'
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  createButton: {
    margin: theme.spacing(2, 0)
  }
}));

const CreateOrg = () => {
  const history = useHistory();

  const classes = useStyles();

  const nameInput = useInputRequired('');
  const formState = useFormState(nameInput);

  const handleCreateOrg = (event:any) => {
    event.preventDefault();

    formState.setLoading();

    createOrg(nameInput.value)
      .then(org => {
        history.replace(`/admin/org/${org.id}`);
      }).catch((error:ApiError) => {
        formState.setError(error.message);
      });
  };

  return (
    <div className={classes.root}>
      <BackButton />
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleCreateOrg}>
                <Typography className={classes.title} variant="h2">
                  Create Org
                </Typography>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Name"
                  name="name"
                  type="text"
                  variant="outlined"
                  { ...nameInput.bind }
                />
                <Button
                  className={classes.createButton}
                  color="primary"
                  disabled={!formState.valid || formState.loading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {formState.loading ? "Creating..." : "Create"}
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateOrg;
