import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useParams, useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Link
} from '@material-ui/core';
import { getOrg } from 'store/api';
import { Org } from "types";
import clsx from "clsx";
import { BackButton, Spinner } from "components";

const useStyles = makeStyles((theme:any) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const createLink = (history:any, path:string, name:string) => {
  return <Typography color="textSecondary" variant="body1">
      <Link
        color="primary"
        onClick={() => history.push(path)}
        underline="always"
        variant="h6"
      >
        {name}
      </Link>
    </Typography>
};

const OrgView = () => {
  const history = useHistory();
  const classes = useStyles();
  const [ org, setOrg ] = useState<Org>();
  const [ error, setError ] = useState<Org>();

  // @ts-ignore
  const { orgId } = useParams();

  useEffect(() => {
    getOrg(orgId)
      .then(setOrg)
      .catch(e => setError(e.message));
  }, [orgId]);

  if(error) {
    return <Typography variant="h4">{error}</Typography>
  }

  if(!org) {
    return <Spinner />;
  }

  return (
    <div className={classes.root}>
      <BackButton />
      <Card className={clsx(classes.root)}>
        <form autoComplete="off" noValidate>
          <CardHeader title={org.name} />
          <Divider />
          <CardContent>
            { createLink(history, `/admin/org/${org.id}/users`, 'Users')}
            { createLink(history, `/admin/org/${org.id}/devices`, 'Devices')}
          </CardContent>
        </form>
      </Card>
    </div>
  );
};

export default OrgView;
