import React, { useState, useContext } from 'react';
import { Context } from 'store';
import { getOrgId } from 'store/coreData';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const AlertEdit = props => {
  const { className, alert, setEditMode, ...rest } = props;
  const classes = useStyles();

  const { deviceId, alertId } = useParams();
  const [state, dispatch] = useContext(Context);
  const [values, setValues] = useState({ ...alert });
  const [updating, setUpdating] = useState(false);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const states = [
    {
      value: 'active',
      label: 'Active'
    },
    {
      value: 'disabled',
      label: 'Disabled'
    }
  ];

  const comparators = [
    '>', '>=', '<', '<=', '='
  ];

  const updateAlert = async event => {
    event.preventDefault();

    setUpdating(true);

    const org = getOrgId(state);

    // TODO
    // try {
    //   const ctx = { org, deviceId, alertId, refresh: true };
    //   await CoreData.DeviceAlertsLoader.updateAlert(state, dispatch, ctx, values);
    //   setEditMode(false);
    // } catch(e) {
    //   console.log(e);
    //   setUpdating(false);
    // }
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          title="Alert"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="Alert Name"
                margin="dense"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Period"
                margin="dense"
                name="period"
                onChange={handleChange}
                required
                value={values.period}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Comparator"
                margin="dense"
                name="comparator"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.comparator}
                variant="outlined"
              >
                {comparators.map(comp => (
                  <option
                    key={comp}
                    value={comp}
                  >
                    {comp}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Threshold"
                margin="dense"
                name="threshold"
                onChange={handleChange}
                required
                value={values.threshold}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Status"
                margin="dense"
                name="status"
                onChange={handleChange}
                required
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.status}
                variant="outlined"
              >
                {states.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Count"
                margin="dense"
                name="count"
                onChange={handleChange}
                required
                value={values.count}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={updateAlert}
          >
            {updating ? "Saving..." : "Save"}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AlertEdit.propTypes = {
  className: PropTypes.string
};

export default AlertEdit;
