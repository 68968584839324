import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { UserData } from 'types';
import { getUsers } from 'store/api';
import moment from "moment/moment";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory, useParams } from "react-router-dom";
import BackButton from "components/BackButton";

const useStyles = makeStyles((theme:any) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

interface UsersToolbarProps {
  orgId:string;
}

const UsersToolbar = (props:UsersToolbarProps) => {
  const { orgId } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleCreateUser = (event:any) => {
    history.push(`/admin/org/${orgId}/users/create`);
  };

  return (
    <div className={classes.root}>
      <Button
        color="primary"
        variant="contained"
        onClick={() => handleCreateUser(orgId)}
      >
        Create User
      </Button>
    </div>
  );
};

interface UsersTableProps {
  users:Array<UserData>;
}

const UsersTable = (props:UsersTableProps) => {
  const { users } = props;
  const history = useHistory();

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event:any, page:any) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event:any) => {
    setRowsPerPage(event.target.value);
  };

  const handleClick = (event:any, deviceId:string) => {
    history.push(`/device/${deviceId}`);
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Last Modified</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.slice(0, rowsPerPage).map(user => (
                <TableRow hover key={user.id} onClick={(event) => handleClick(event, user.id)}>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {moment(user.created).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell>
                    {moment(user.modified).format('MM/DD/YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={users.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

const UserList = () => {
  const classes = useStyles();
  const [ users, setUsers ] = useState<Array<UserData>>([]);

  // @ts-ignore
  const { orgId } = useParams();

  useEffect(() => {
    getUsers(orgId).then(setUsers);
  }, []);

  return (
    <div className={classes.root}>
      <BackButton />
      <UsersToolbar orgId={orgId} />
      <div className={classes.content}>
        <UsersTable users={users} />
      </div>
    </div>
  );
};

export default UserList;
