import { useContext, useEffect } from 'react';
import { Context } from 'store';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { getAppInitData } from 'store/api';

const useStyles = makeStyles((theme:any) => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const Loading = () => {
  const { mutator } = useContext(Context);
  const classes = useStyles();

  useEffect(() => {
    getAppInitData()
      .then(data => {
        mutator.setUser(data.user);
        mutator.setAppLoaded(true);
      })
      .catch(e => console.error(e));
  }, [mutator]);

  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h1">
              Loading...
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Loading;
