import React, { useContext } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import {Context, Selector} from "store";
import { RouteWithLayout } from 'components';

import MainLayout from './layouts/Main';
import MinimalLayout from './layouts/Minimal';

import {
  Dashboard as DashboardView,
  Account as AccountView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  ComingSoon as ComingSoonView,
  Device as DeviceView,
  Alert as AlertView,
  Ticket as TicketView,
  CreateDevice as CreateDeviceView,
  OrgsList as AdminOrgsListView
} from './views';

import LoadingView from './views/Loading';

import DeviceList from './views/DeviceList';

import CreateOrg from './views/Admin/CreateOrg';
import OrgView from './views/Admin/OrgView';
import AdminUserList from './views/Admin/UserList';
import AdminCreateUser from './views/Admin/CreateUser';
import AdminDevicesList from './views/Admin/DevicesList';
import AdminCreateDevice from './views/Admin/CreateDevice';
import AdminDeviceView from './views/Admin/DeviceView';
import AdminCreateTrigger from './views/Admin/CreateTrigger';
import AdminTriggerRecipients from './views/Admin/TriggerRecipients';

const constructRoute = (path:string, component:React.FC, layout:React.FC, auth:boolean, isAdmin:boolean, exact:boolean) => {
  return <RouteWithLayout
    component={component}
    exact={exact}
    layout={MainLayout}
    path={path}
    auth={auth}
    isAdmin={isAdmin}
  />;
};

const constructOpenRoute = (path:string, component:React.FC, layout:React.FC) =>
  constructRoute(path, component, layout, false, false, true);

const constructAdminRoute = (path:string, component:React.FC, layout:React.FC) =>
  constructRoute(path, component, layout, true, true, true);

const constructAuthRoute = (path:string, component:React.FC, layout:React.FC) =>
  constructRoute(path, component, layout, true, false, true);

const getHomePage = (selector: Selector):string => {
  if(selector.isOrbAdmin()) {
    return "/admin/orgs";
  }

  if(selector.isLoggedIn()) {
    return "/devices";
  }

  return "/sign-in";
}

const Routes = () => {
  const { state, selector } = useContext(Context);

  if(!state.appLoaded) {
    return <LoadingView />
  }

  return (
    <Switch>
      /** Redirect HomePage to SignIn **/
      <Redirect exact from="/" to={getHomePage(selector)} />

      /** Open Pages **/
      { constructOpenRoute("/sign-up", SignUpView, MinimalLayout) }
      { constructOpenRoute("/sign-in", SignInView, MinimalLayout) }
      { constructOpenRoute("/not-found", NotFoundView, MinimalLayout) }

      /** Org Pages **/
      { constructAuthRoute("/coming-soon", ComingSoonView, MainLayout) }
      { constructAuthRoute("/dashboard", DashboardView, MainLayout) }
      { constructAuthRoute("/account", AccountView, MainLayout) }
      { constructAuthRoute("/devices", DeviceList, MainLayout) }
      { constructAuthRoute("/devices/create", CreateDeviceView, MainLayout) }
      { constructAuthRoute("/device/:deviceId", DeviceView, MainLayout) }
      { constructAuthRoute("/device/:deviceId/alert/:alertId", AlertView, MainLayout) }
      { constructAuthRoute("/device/:deviceId/alert/:alertId/ticket/:ticketId", TicketView, MainLayout) }

      /** Admin Pages **/
      { constructAdminRoute("/admin/orgs", AdminOrgsListView, MainLayout) }
      { constructAdminRoute("/admin/org/create", CreateOrg, MainLayout) }
      { constructAdminRoute("/admin/org/:orgId", OrgView, MainLayout) }
      { constructAdminRoute("/admin/org/:orgId/users", AdminUserList, MainLayout) }
      { constructAdminRoute("/admin/org/:orgId/users/create", AdminCreateUser, MainLayout) }
      { constructAdminRoute("/admin/org/:orgId/devices", AdminDevicesList, MainLayout) }
      { constructAdminRoute("/admin/org/:orgId/devices/create", AdminCreateDevice, MainLayout) }
      { constructAdminRoute("/admin/org/:orgId/devices/:deviceId", AdminDeviceView, MainLayout) }
      { constructAdminRoute("/admin/org/:orgId/devices/:deviceId/signals/:signalId/triggers/create", AdminCreateTrigger, MainLayout) }
      { constructAdminRoute("/admin/org/:orgId/devices/:deviceId/signals/:signalId/triggers/:triggerId/recipients", AdminTriggerRecipients, MainLayout) }

      /** Default Page **/
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
