import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom";

interface BackButtonProps {
  onBack?: () => void;
}

const BackButton = (props:BackButtonProps) => {
  const { onBack } = props;

  const history = useHistory();

  const handleBack = () => {
    onBack ? onBack() : history.goBack();
  };

  return (
    <IconButton onClick={handleBack}>
      <ArrowBackIcon />
    </IconButton>
  );
};

export default BackButton;
