import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: any) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

interface DevicesToolbarProps {
  className?: string;
}

export const DevicesToolbar = (props: DevicesToolbarProps) => {
  const { className, ...rest } = props;
  const history = useHistory();
  const classes = useStyles();

  const handleCreateDeviceClick = () => {
    history.push(`/devices/create`);
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleCreateDeviceClick() }
        >
          Create Device
        </Button>
      </div>
    </div>
  );
};

export default DevicesToolbar;
