import { useContext } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  TextField,
  Link,
  Typography
} from '@material-ui/core';
import { Context } from "../store";
import { login } from 'store/api';
import { useFormState, useInputEmail, useInputPassword } from 'hooks';
import { ApiError } from "../store/api";

const useStyles = makeStyles((theme:any) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  formError: {
    marginTop: theme.spacing(3),
    color: '#e53935'
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn = (props:any) => {
  const { mutator } = useContext(Context);
  const history = useHistory();

  const classes = useStyles();

  const emailInput = useInputEmail('');
  const passwordInput = useInputPassword('');
  const formState = useFormState(emailInput, passwordInput);

  const handleSignIn = (event:any) => {
    event.preventDefault();

    formState.setLoading();

    login(emailInput.value, passwordInput.value)
      .then(data => {
        mutator.setUser(data.user);
        mutator.setDevices(data.devices);
        history.replace('/devices');
      }).catch((error:ApiError) => {
        formState.setError(error.message);
      });
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                <Typography className={classes.title} variant="h2">
                  Sign in
                </Typography>
                <Typography className={classes.formError} variant="h5">
                  {formState.error}
                </Typography>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Email address"
                  name="email"
                  type="text"
                  variant="outlined"
                  { ...emailInput.bind }
                />
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Password"
                  name="password"
                  type="password"
                  variant="outlined"
                  { ...passwordInput.bind }
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.valid || formState.loading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {formState.loading ? "Signing In..." : "Sign in now"}
                </Button>
                <Typography color="textSecondary" variant="body1">
                  Don't have an account?{' '}
                  <Link component={RouterLink} to="/sign-up" variant="h6">
                    Sign up
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignIn;
