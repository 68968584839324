import { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Context } from 'store';
import { Signal } from 'types';
import { useParams, useHistory } from "react-router-dom";
import { BackButton, Spinner } from "components";
import TriggersToolbar from './TriggersToolbar';
import TriggersTable from './TriggersTable';
import DeviceDetails from './DeviceDetails';

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

interface PageParams {
  deviceId: string;
}

export const Device = () => {
  const history = useHistory();
  const classes = useStyles();
  const { selector } = useContext(Context);
  const { deviceId } = useParams<PageParams>();

  const device = selector.selectDevice(deviceId);

  if (!device) {
    return <Spinner/>;
  }

  const triggers: any[] = []; // selectTriggers(state, deviceId);
  const signals: Signal[] = []; //selectDeviceSignals(state, deviceId);

  const handleBack = () => {
    if (history.length === 2) {
      history.replace('/devices');
    } else {
      history.goBack();
    }
  };

  return (
    <div className={classes.root}>
      <BackButton onBack={handleBack} />
      <DeviceDetails device={device} signals={signals} />
      <TriggersToolbar />
      <div className={classes.content}>
        <TriggersTable deviceId={deviceId} triggers={triggers} />
      </div>
    </div>
  );
};

export default Device;
