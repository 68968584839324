import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Context } from "store";

interface RouteWithLayoutProps {
  component:any;
  layout:any;
  path:any;
  auth?:boolean;
  isAdmin?:boolean;
  exact?:boolean;
}

const RouteWithLayout = (props:RouteWithLayoutProps) => {
  const { selector } = useContext(Context);

  const { layout: Layout, component: Component, auth, isAdmin, ...rest } = props;

  if(auth && !selector.isLoggedIn() || isAdmin && !selector.isOrbAdmin()) {
    return <Redirect to='/sign-in' />
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export default RouteWithLayout;
