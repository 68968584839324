import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Device, DeviceState, Signal, Trigger, TriggerMap, TriggerState } from 'types';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CardHeader,
  Divider, Button, TableFooter, Link
} from '@material-ui/core';
import SignalWifi4BarLockIcon from '@material-ui/icons/SignalWifi4BarLock';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import { useHistory, useParams } from "react-router-dom";
import BackButton from "components/BackButton";
import clsx from "clsx";
import { ackTriggerTicket, ApiError, closeTriggerTicket, getDevice } from "../../store/api";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

const useStyles = makeStyles((theme:any) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  online: {
    color: "#75DB75",
    marginLeft: "10px"
  },
  offline: {
    color: "#E5E5E5",
    marginLeft: "10px"
  },
  active: {
    color: "#ef4646",
    marginLeft: "10px"
  },
  inactive: {
    color: "#E5E5E5",
    marginLeft: "10px"
  }
}));

interface DevicesToolbarProps {
  orgId: string;
  deviceId: string;
  signalId: string;
}

const TriggerTableFooter = (props:DevicesToolbarProps) => {
  const { orgId, deviceId, signalId } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleCreateDevice = (event:any) => {
    history.push(`/admin/org/${orgId}/devices/${deviceId}/signals/${signalId}/triggers/create`);
  };

  return (
    <div className={classes.root}>
      <Button
        color="primary"
        variant="contained"
        onClick={() => handleCreateDevice(orgId)}
      >
        Create Trigger
      </Button>
    </div>
  );
};

interface TriggersTableStatusProps {
  orgId: string;
  deviceId: string;
  signalId: string;
  triggerId: string;
  triggerState: TriggerState;
}

const TriggerTableStatus = (props: TriggersTableStatusProps) => {
  const { orgId, deviceId, signalId, triggerId, triggerState } = props;
  const classes = useStyles();
  const isActive = !!triggerState.open;
  const history = useHistory();

  if(!isActive) {
    return <NotificationsNoneIcon className={classes.inactive} />
  }

  const handleStatusActionClick = (event: any, action: 'ack' | 'close') => {
    event.preventDefault();

    if(action === 'ack') {
      ackTriggerTicket({
        orgId, deviceId, signalId, triggerId, ticketId: triggerState.open
      }).then(() => {
        history.go(0);
      }).catch((error: ApiError) => {
        // TODO: error!
        console.log(error);
      });
    } else if(action === 'close') {
      closeTriggerTicket({
        orgId, deviceId, signalId, triggerId, ticketId: triggerState.open
      }).then(() => {
        history.go(0);
      }).catch((error: ApiError) => {
        // TODO: error!
        console.log(error);
      });
    }
  }

  return (
    <>
      <NotificationsActiveIcon className={classes.active} />
      {!triggerState.ack &&
        <Link href="#" onClick={(e: any) => handleStatusActionClick(e, 'ack')}>
          Acknowledge
        </Link>
      }
      {!!triggerState.ack && !triggerState.close &&
      <Link href="#" onClick={(e: any) => handleStatusActionClick(e, 'close')}>
        Close
      </Link>
      }
    </>
  )
}

interface TriggersTableProps {
  orgId: string;
  deviceId: string;
  signalId: string;
  triggers:Record<string, Trigger>;
  triggersState: TriggerMap;
}

const TriggersTable = (props:TriggersTableProps) => {
  const { orgId, deviceId, signalId, triggersState } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleTriggerClick = (event:any, triggerId:string) => {
    event.preventDefault();
    history.push(`/admin/org/${orgId}/devices/${deviceId}/signals/${signalId}/triggers/${triggerId}/recipients`);
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Count</TableCell>
              <TableCell>Comparator</TableCell>
              <TableCell>Threshold</TableCell>
              <TableCell>Period</TableCell>
              <TableCell>Recipients</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(triggersState).map(triggerId => {
              const triggerState = triggersState[triggerId];
              // const trigger = triggers[triggerId];

              return (
                <TableRow hover key={triggerId}>
                  <TableCell><TriggerTableStatus orgId={orgId} deviceId={deviceId} signalId={signalId} triggerId={triggerId} triggerState={triggerState} /></TableCell>
                  <TableCell>{triggerState.count}</TableCell>
                  <TableCell>{triggerState.comparator}</TableCell>
                  <TableCell>{triggerState.threshold}</TableCell>
                  <TableCell>{triggerState.period}</TableCell>
                  <TableCell>
                    <Link href="#" onClick={(e:any) => handleTriggerClick(e, triggerId)}>
                      Recipients
                    </Link>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                <TriggerTableFooter orgId={orgId} deviceId={deviceId} signalId={signalId} />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </CardContent>
    </Card>
  );
};

interface SignalCardProps {
  orgId: string;
  deviceId: string;
  signalId:string;
  signal: Signal;
  triggerState: TriggerMap;
}

const SignalCard = (props: SignalCardProps) => {
  const { orgId, deviceId, signalId, signal, triggerState } = props;

  return (
    <CardContent key={signalId}>
      <Typography variant="h2">{signal.name || 'Unnamed Signal'} - {signal.type}</Typography>
      <TriggersTable orgId={orgId} deviceId={deviceId} signalId={signalId} triggers={signal.triggers} triggersState={triggerState} />
    </CardContent>
  )
}

interface DeviceViewParams {
  orgId: string;
  deviceId: string;
}

const DeviceView = () => {
  const classes = useStyles();
  const { orgId, deviceId } = useParams<DeviceViewParams>();
  const [device, setDevice] = useState<Device>(undefined as unknown as Device);

  useEffect(() => {
    getDevice(orgId, deviceId).then(setDevice);
  }, []);

  if(!device) {
    return <></>
  }

  const deviceState = (device.deviceState && device.deviceState.reported) ?? {} as DeviceState;
  const isConnected = !!deviceState.connected;

  return (
    <div className={classes.root}>
      <BackButton />
      <Card className={clsx(classes.root)}>
        <form autoComplete="off" noValidate>
          <CardHeader
            title={
              <Typography variant="h2">
                {device.name}
                { isConnected && <SignalWifi4BarLockIcon className={classes.online}/> }
                { !isConnected && <SignalWifiOffIcon className={classes.offline}/> }
              </Typography>
            }
            subheader={!device.provisioned && `ProvisionKey: ${device.provisionKey}`}
          />

          <Divider />
          <CardContent>
            {
              Object.entries(device.signals)
                .map(([signalId, signal]) => {
                  const triggerState = deviceState.triggers || {};
                  const signalTriggers = triggerState[signalId] || {};

                  return <SignalCard key={signalId} orgId={orgId} deviceId={deviceId} signalId={signalId} signal={signal} triggerState={signalTriggers} />
                })
            }
          </CardContent>
        </form>
      </Card>
    </div>
  );
};

export default DeviceView;
