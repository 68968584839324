import {Dispatch, SetStateAction, useState} from "react";
import validate from 'validate.js';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  required: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  checkRequired: {
    presence: { allowEmpty: false, message: 'is required' },
    checked: true
  },
  selectRequired: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  phone: {
    format: {
      pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    }
  }
};

interface FormInput {
  isError: boolean;
  touched: boolean;
  reset: () => void;
  error:string|undefined;
}

interface InputState<T> extends FormInput {
  value:T;
  setValue:Dispatch<SetStateAction<T>>;
  bind: {};
}

interface InputOptions {
  constraints?: any;
  isCheckbox?: boolean;
  isSelect?: boolean;
}

export interface ArrayInput<T> extends FormInput {
  value:T[];
  push: (value: T) => void;
  delete: (value: T) => void;
}

export const useArrayInput = <T> (initialValue:T[]):ArrayInput<T> => {
  const [value, setValue] = useState<T[]>(initialValue);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState();

  const isError = !!error;

  return {
    value,
    reset: () => {
      setValue(initialValue);
      setTouched(!!initialValue);
      setError(undefined);
    },
    push: (val: T) => {
      setValue(existing => {
        return [
          ...(existing.filter(e => e != val)),
          val
        ]
      });
      setTouched(true);
    },
    delete: (val: T) => {
      setValue(state => state.filter(e => e != val));
      setTouched(true);
    },
    error,
    isError,
    touched,
  };
}

const useInput = <T> (initialValue:T, options: InputOptions):InputState<T> => {
  const { constraints, isCheckbox, isSelect } = options;

  const [value, setValue] = useState<T>(initialValue);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState();

  const isError = !!error;
  const helper = isSelect ? {} : { helperText: error };

  return {
    value,
    setValue,
    reset: () => {
      setValue(initialValue);
      setTouched(!!initialValue);
      setError(undefined);
    },
    error,
    isError,
    touched,
    bind: {
      value,
      [isCheckbox ? 'value' : 'checked']: value,
      onChange: (event:any) => {
        const newValue = event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value;

        setTouched(true);
        setValue(newValue);
        const error = validate.single(newValue, constraints);
        setError(error);
      },
      ...helper,
      error: isError
    }
  };
};

export const useInputEmail = (initialValue:string) => useInput(initialValue, { constraints: schema.email });
export const useInputPhone = (initialValue:string) => useInput(initialValue, { constraints: schema.phone });
export const useInputPassword = (initialValue:string) => useInput(initialValue, { constraints: schema.password });
export const useInputRequired = (initialValue:string) => useInput(initialValue, { constraints: schema.required });
export const useCheckRequired = (initialValue:boolean) => useInput(initialValue, { constraints: schema.checkRequired, isCheckbox: true });
export const useSelectRequired = (initialValue:string) => useInput(initialValue, { constraints: schema.selectRequired, isSelect: true });

interface FormState {
  valid:boolean,
  setLoading: () => void,
  loading:boolean,
  setError: (error:string) => void,
  error:string|undefined
}

export const useFormState = (...inputs: FormInput[]): FormState => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string|undefined>(undefined);

  const anyTouched = inputs.filter(input => input.touched).length > 0;
  const valid = anyTouched && inputs.filter(input => input.isError).length === 0;

  return {
    valid,
    loading,
    setLoading: () => {
      setError(undefined);
      setLoading(true);
    },
    setError: (error:string) => {
      setError(error);
      setLoading(false);
    },
    error
  };
};

interface CheckState {
  values:Array<string>,
  bind: (name:string) => {
  }
}

export const useCheckInputs = ():CheckState => {
  const [values, setValues] = useState<Array<string>>([]);

  return {
    values,
    bind: (name:string) => ({
      checked: values.indexOf(name) !== -1,
      name,
      onChange: (event:any) => {
        const newValues = values.filter(e => e !== name);
        if(event.target.checked) {
          newValues.push(name);
        }
        setValues(newValues);
      },
    })
  };
};
