import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme:any) => ({
  root: {
    padding: theme.spacing(4)
  },
  iframe: {
    width: '100%',
    minHeight: 640,
    border: 0
  }
}));

const ComingSoon = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>Coming Soon...</h1>
    </div>
  );
};

export default ComingSoon;
