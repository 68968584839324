import { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {Divider, Drawer, Typography} from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import RouterIcon from '@material-ui/icons/Router';
import { Context } from "store";

import Profile from './components/Profile';
import SidebarNav from './components/SidebarNav';
import UpgradePlan from './components/UpgradePlan';

const useStyles = makeStyles((theme: any) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('md')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

interface SidebarProps {
  className?: string
  onClose: () => void
  open: boolean
  variant: "permanent" | "persistent" | "temporary" | undefined
}

const Sidebar = (props:SidebarProps) => {
  const { open, variant, onClose, className, ...rest } = props;
  const { selector } = useContext(Context);

  const classes = useStyles();

  const signedOutPages:any = [

    // {
    //   title: 'Dashboard',
    //   href: '/dashboard',
    //   icon: <DashboardIcon />
    // },
    // {
    //   title: 'Users',
    //   href: '/users',
    //   icon: <PeopleIcon />
    // },
    // {
    //   title: 'Products',
    //   href: '/products',
    //   icon: <ShoppingBasketIcon />
    // },
    // {
    //   title: 'Authentication',
    //   href: '/sign-in',
    //   icon: <LockOpenIcon />
    // },
    // {
    //   title: 'Typography',
    //   href: '/typography',
    //   icon: <TextFieldsIcon />
    // },
    // {
    //   title: 'Icons',
    //   href: '/icons',
    //   icon: <ImageIcon />
    // },
    // {
    //   title: 'Account',
    //   href: '/account',
    //   icon: <AccountBoxIcon />
    // },
    // {
    //   title: 'Settings',
    //   href: '/settings',
    //   icon: <SettingsIcon />
    // }
  ];

  const signedInPages = [
    {
      title: 'Devices',
      href: '/devices',
      icon: <RouterIcon />
    }
  ];

  const adminPages = [
    {
      title: 'Organizations',
      href: '/admin/orgs',
      icon: <BusinessIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        { selector.isLoggedIn() && (
          <>
            <Profile />
            <SidebarNav className={classes.nav} pages={signedInPages} />
          </>
        )}
        { !selector.isLoggedIn() && <SidebarNav className={classes.nav} pages={signedOutPages} /> }
        { false && <UpgradePlan /> }
        { selector.isOrbAdmin() && (
          <>
            <Divider className={classes.divider} />
            <Typography variant="h4" align={"center"}>Admin</Typography>
            <SidebarNav className={classes.nav} pages={adminPages} />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default Sidebar;
