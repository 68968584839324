import {createContext, Dispatch, useState} from 'react';
import {Device, UserData} from "types";

export interface GlobalState {
  appLoaded: boolean;
  user: UserData | undefined;
  devices: Array<Device> | undefined;
}

export interface Selector {
  isLoggedIn: () => boolean;
  isOrbAdmin: () => boolean;
  selectDevice: (deviceId: string) => Device|undefined;
}

export interface Mutator {
  setAppLoaded: Dispatch<boolean>;
  setUser: Dispatch<UserData>;
  setDevices: Dispatch<Array<Device>>;
  logout: () => void;
}

export const getUserData = (state:GlobalState):UserData | undefined => state.user;
export const selectDevices = (state:GlobalState):Array<Device> | undefined => state.devices;
export const isLoggedIn = (state:GlobalState) => !!state.user;
export const getOrgId = (state:GlobalState) => getUserData(state)?.orgId;
export const getGroups = (state:GlobalState) => getUserData(state)?.groups;
export const isOrbAdmin = (state:GlobalState) => (getGroups(state) ?? []).indexOf('orb-admin') !== -1;
export const selectDevice = (state:GlobalState, deviceId: string):Device|undefined => (selectDevices(state) ?? []).find(e => e.id === deviceId);

export interface GlobalStateStore {
  state: GlobalState,
  mutator: Mutator,
  selector: Selector
}

export const useGlobalState = (): GlobalStateStore => {
  const [appLoaded, setAppLoaded] = useState(false);
  const [user, setUser] = useState<UserData | undefined>(undefined);
  const [devices, setDevices] = useState<Array<Device> | undefined>(undefined);

  const state: GlobalState = {
    appLoaded,
    user,
    devices
  }

  return {
    state,
    mutator: {
      setAppLoaded,
      setUser,
      setDevices,
      logout: () => {
        setUser(undefined);
        setDevices(undefined);
      }
    },
    selector: {
      isLoggedIn: () => isLoggedIn(state),
      isOrbAdmin: () => isOrbAdmin(state),
      selectDevice: deviceId => selectDevice(state, deviceId)
    }
  };
};

export const Context = createContext<GlobalStateStore>(undefined as unknown as GlobalStateStore);
