import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Typography
} from '@material-ui/core';
import moment from "moment/moment";
import axios from "axios/index";
import { Context } from 'store'

const useStyles = makeStyles(theme => ({
  root: {},
  ackButton: {
    marginRight: theme.spacing(1)
  }

}));

const TicketDetails = props => {
  const { className, ticket, setTicket, ...rest } = props;
  const { org, deviceId, triggerId, id } = ticket;
  const [state, dispatch] = useContext(Context);

  const classes = useStyles();

  const isAcked = !!ticket.ackTime;
  const isClosed = !!ticket.closedTime;

  const handleAck = async event => {
    event.preventDefault();

    const response = await axios.put(`/org/${org}/tickets/${deviceId}/${triggerId}/${id}/ack`);
    const { ticket } = response.data;
    setTicket(ticket);
  };

  const handleClose = async event => {
    event.preventDefault();

    // TODO: finish this
    // const response = await axios.put(`/org/${org}/tickets/${deviceId}/${triggerId}/${id}/close`);
    // const { trigger, ticket } = response.data;
    //
    // CoreData.DeviceTriggersLoader.mergeSingleTrigger(dispatch, trigger);

    setTicket(ticket);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          title="Ticket"
        />
        <Divider />
        <CardContent>
          <Typography variant="h4">{ticket.id}</Typography>
          <p>Created: {moment(ticket.created*1000).format('MMMM Do YYYY, h:mm:ss a')}</p>
          <Button
            className={classes.ackButton}
            color="primary"
            disabled={isAcked}
            size="large"
            type="submit"
            variant="contained"
            onClick={handleAck}
          >
            Ack
          </Button>
          <Button
            className={classes.closeButton}
            color="primary"
            disabled={isClosed}
            size="large"
            type="submit"
            variant="contained"
            onClick={handleClose}
          >
            Close
          </Button>
        </CardContent>
      </form>
    </Card>
  );
};

TicketDetails.propTypes = {
  className: PropTypes.string,
  ticket: PropTypes.object.isRequired
};

export default TicketDetails;
