import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import BackButton from "components/BackButton";
import { useFormState, useInputRequired, useCheckInputs } from 'hooks';

const useStyles = makeStyles((theme:any) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  createButton: {
    margin: theme.spacing(2, 0)
  }
}));

const CreateDevice = () => {
  // TODO

  // const { state } = useContext(Context);
  // const history = useHistory();

  const classes = useStyles();

  const nameInput = useInputRequired('');
  const formState = useFormState(nameInput);
  const signalTypes = useCheckInputs();

  const handleCreateDevice = (event:any) => {
    event.preventDefault();

    formState.setLoading();

    // const orgId = getOrgId(state);
    //
    // createDevice(orgId, nameInput.value, signalTypes.values)
    //   .then(data => {
    //     dispatch({ type: 'DeviceCreated', payload: data });
    //     history.replace('/devices');
    //   }).catch((error:ApiError) => {
    //     formState.setError(error.message);
    //   });
  };

  return (
    <div className={classes.root}>
      <BackButton />
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleCreateDevice}>
                <Typography className={classes.title} variant="h2">
                  Create Device
                </Typography>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Name"
                  name="name"
                  type="text"
                  variant="outlined"
                  { ...nameInput.bind }
                />
                <FormControlLabel
                  control={<Checkbox { ...signalTypes.bind('counter')} />}
                  label="Counter"
                />
                <FormControlLabel
                  control={<Checkbox { ...signalTypes.bind('esp32_temp')} />}
                  label="Board Temp"
                />
                <FormControlLabel
                  control={<Checkbox { ...signalTypes.bind('pt100_temp')} />}
                  label="PT100 Temp"
                />
                <FormControlLabel
                  control={<Checkbox { ...signalTypes.bind('aquapro_modbus_conductivity')} />}
                  label="AquaPro Modbus Conductivity"
                />
                <FormControlLabel
                  control={<Checkbox { ...signalTypes.bind('aquapro_modbus_ph')} />}
                  label="AquaPro Modbus Ph"
                />
                <FormControlLabel
                  control={<Checkbox { ...signalTypes.bind('aquapro_modbus_temp')} />}
                  label="AquaPro Modbus Temp"
                />
                <Button
                  className={classes.createButton}
                  color="primary"
                  disabled={!formState.valid || signalTypes.values.length === 0 || formState.loading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {formState.loading ? "Creating..." : "Create"}
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateDevice;
