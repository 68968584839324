import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Context } from 'store';
import { getOrgId } from 'store/coreData';
import { useParams } from "react-router-dom";
import { BackButton, Spinner } from "components";
import { TicketDetails } from './components';
import axios from "axios/index";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Ticket = props => {
  const { history } = props;
  const classes = useStyles();
  const { state } = useContext(Context);
  const [ ticket, setTicket ] = useState();

  const { deviceId, triggerId, ticketId } = useParams();
  const org = getOrgId(state);

  useEffect(() => {
    const fetchTicket = async () => {
      const response = await axios.get(`/org/${org}/tickets/${deviceId}/${triggerId}/${ticketId}`);
      const { ticket } = response.data;
      setTicket(ticket);
    };

    fetchTicket();
  }, [ticketId]);

  if(!ticket) {
    return <Spinner />;
  }

  const handleBack = () => {
    if (history.length === 2) {
      history.replace('/devices');
    } else {
      history.goBack();
    }
  };

  return (
    <div className={classes.root}>
      <BackButton onBack={handleBack} />
      <TicketDetails ticket={ticket} setTicket={setTicket} />
    </div>
  );
};

export default Ticket;
