import { useContext } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import { Context } from "store";
import { logout, ApiError } from 'store/api';

const useStyles = makeStyles((theme:any) => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

interface TopbarProps {
  className: any;
  onSidebarOpen: any;
}

const Topbar = (props:TopbarProps) => {
  const { mutator } = useContext(Context);
  const { className, onSidebarOpen, ...rest } = props;
  const history = useHistory();
  const { selector } = useContext(Context);

  const triggersWithTickets = []; //selectTriggersWithTickets(state);

  const classes = useStyles();

  // const [notifications] = useState([]);

  const handleLogout = (event:any) => {
    event.preventDefault();

    logout()
      .then(data => {
        mutator.logout();
        history.replace('/');
      }).catch((error:ApiError) => {
        // TODO handle error
      });
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/logo-white.svg"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        {selector.isLoggedIn() && (
          <>
            <IconButton color="inherit">
              <Badge
                badgeContent={triggersWithTickets.length}
                color="error"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Hidden smDown>
              <IconButton
                className={classes.signOutButton}
                color="inherit"
                onClick={handleLogout}
              >
                <InputIcon />
              </IconButton>
            </Hidden>
            <Hidden mdUp>
              <IconButton
                color="inherit"
                onClick={onSidebarOpen}
              >
                <MenuIcon/>
              </IconButton>
            </Hidden>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
