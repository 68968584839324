import { Device, TriggerEmailConfig, TriggerSMSConfig } from "types";

export const getTriggerRecipientsConfig = (device: Device, signalId: string, triggerId: string): { email?: TriggerEmailConfig; sms?: TriggerSMSConfig; } => {
  if(
    !device.signals ||
    !device.signals[signalId] ||
    !device.signals[signalId].triggers ||
    !device.signals[signalId].triggers[triggerId]
  ) {
    return {};
  }

  return device.signals[signalId].triggers[triggerId];
}
