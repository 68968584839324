import {useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import {Context} from "store";
import {UserData} from "types";

const useStyles = makeStyles((theme:any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

export default () => {
  const { state } = useContext(Context);
  const classes = useStyles();

  const { email, orgId } = state.user as UserData;

  return (
    <div className={clsx(classes.root)}>
      <Typography className={classes.name} variant="h4">
        {email}
      </Typography>
      <Typography variant="body2">{orgId}</Typography>
    </div>
  );
};
