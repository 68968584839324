import { Device, Signal } from 'types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

interface DeviceDetailsProps {
  device: Device;
  signals: Array<Signal>;
}

const DeviceDetails = (props:DeviceDetailsProps) => {
  const { device, signals } = props;

  const classes = useStyles();

  return (
    <Card className={clsx(classes.root)}>
      <form autoComplete="off" noValidate>
        <CardHeader
          title="Device"
        />
        <Divider />
        <CardContent>
          <Typography variant="h4">DeviceId: {device.id}</Typography>
          <Typography variant="h4">ProvisionKey: {device.provisionKey}</Typography>
          {signals.map(e => {
            return <Typography variant="h4" key={e.name}>Signal: {e.name}</Typography>
          })}
        </CardContent>
      </form>
    </Card>
  );
};

export default DeviceDetails;
