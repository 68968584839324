import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  TextField,
  Typography,
  Card, CardContent, Chip
} from '@material-ui/core';
import { useFormState, useInputEmail, useInputPhone, useInputRequired, useArrayInput, ArrayInput } from 'hooks';
import BackButton from "components/BackButton";
import { ApiError, getDevice, updateTriggerEmailConfig, updateTriggerSmsConfig } from "store/api";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Device, TriggerEmailConfig, TriggerSMSConfig } from "types";
import LoadingView from 'views/Loading';
import { getTriggerRecipientsConfig } from "store/selectors";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Theme } from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles((theme:Theme) => ({
  root: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    maxWidth: 500
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  form: {
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  createButton: {
    margin: theme.spacing(2, 0)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  addEmailButtonActive: {
    cursor: "pointer"
  },
  addEmailButtonInActive: {
  },
  chips: {
    marginTop: theme.spacing(2),
  },
}));

const DeletableChips = (props: { recipientsInput: ArrayInput<string> }) => {
  const classes = useStyles();

  const handleDelete = (e:Event, chip: string) => {
    props.recipientsInput.delete(chip);
  }

  return (
      <div className={classes.chips}>
        {
          props.recipientsInput.value.map(chip =>
            <Chip
              key={chip}
              label={chip}
              onDelete={e => handleDelete(e, chip)}
              variant="outlined"
            />
          )
        }
      </div>
  )
}

interface EmailRecipientCardProps {
  orgId: string;
  deviceId: string;
  signalId: string;
  triggerId: string;
  defaultEmail: TriggerEmailConfig | undefined;
}

const EmailRecipientCard = (props: EmailRecipientCardProps) => {
  const { orgId, deviceId, signalId, triggerId, defaultEmail } = props;

  const history = useHistory();
  const classes = useStyles();

  const { subject, body, recipients: defaultRecipients } = defaultEmail || {};

  const subjectInput = useInputRequired(subject || '');
  const bodyInput = useInputRequired(body || '');
  const recipientInput = useInputEmail('');
  const recipientsInputs = useArrayInput<string>(defaultRecipients || []);

  const formState = useFormState(subjectInput, bodyInput, recipientsInputs);

  const handleUpdate = (event:any) => {
    event.preventDefault();

    formState.setLoading();

    updateTriggerEmailConfig({
      orgId, deviceId, signalId, triggerId,
      subject: subjectInput.value,
      body: bodyInput.value,
      recipients: recipientsInputs.value
    }).then(() => {
      history.go(0);
    }).catch((error:ApiError) => {
      formState.setError(error.message);
    });
  };

  const handleRecipientInput = (event:any) => {
    event.preventDefault();

    if(!recipientInput.touched || recipientInput.isError) {
      return;
    }

    recipientsInputs.push(recipientInput.value);
    recipientInput.reset();
  }

  const getButtonText = () => {
    if(!!defaultEmail) {
      return formState.loading ? "Updating..." : "Update"
    } else {
      return formState.loading ? "Creating..." : "Create"
    }
  }

  return (
    <Card className={clsx(classes.root)}>
      <CardContent>
        <form className={classes.form} onSubmit={handleUpdate}>
          <Typography className={classes.title} variant="h2">
            Email Recipients
          </Typography>
          <TextField
            className={classes.textField}
            label="Subject"
            name="subject"
            type="text"
            variant="outlined"
            fullWidth
            { ...subjectInput.bind }
          />
          <TextField
            className={classes.textField}
            label="Body"
            name="body"
            type="text"
            variant="outlined"
            fullWidth
            { ...bodyInput.bind }
          />
          {recipientsInputs.value.length > 0 &&
            <DeletableChips recipientsInput={recipientsInputs} />
          }
          <TextField
            className={classes.textField}
            label="Add Email"
            name="addemail"
            type="text"
            variant="outlined"
            fullWidth
            { ...recipientInput.bind }
            InputProps={{
              endAdornment: <AddCircleIcon
                onClick={handleRecipientInput}
                className={
                  (recipientInput.touched && !recipientInput.isError) ?
                    classes.addEmailButtonActive :
                    classes.addEmailButtonInActive
                }
              />
            }}
          />
          <Button
            className={classes.createButton}
            color="primary"
            disabled={!formState.valid || formState.loading}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            {getButtonText()}
          </Button>
        </form>
      </CardContent>
    </Card>
  )
}

interface SMSRecipientCardProps {
  orgId: string;
  deviceId: string;
  signalId: string;
  triggerId: string;
  defaultSMS: TriggerSMSConfig | undefined;
}

const SMSRecipientCard = (props: SMSRecipientCardProps) => {
  const { orgId, deviceId, signalId, triggerId, defaultSMS } = props;

  const history = useHistory();
  const classes = useStyles();

  const { message, recipients: defaultRecipients } = defaultSMS || {};

  const messageInput = useInputRequired(message || '');
  const recipientInput = useInputPhone('');
  const recipientsInputs = useArrayInput<string>(defaultRecipients || []);

  const formState = useFormState(messageInput, recipientsInputs);

  const handleUpdate = (event:any) => {
    event.preventDefault();

    formState.setLoading();

    updateTriggerSmsConfig({
      orgId, deviceId, signalId, triggerId,
      message: messageInput.value,
      recipients: recipientsInputs.value
    }).then(() => {
      history.go(0);
    }).catch((error:ApiError) => {
      formState.setError(error.message);
    });
  };

  const handleRecipientInput = (event:any) => {
    event.preventDefault();

    if(!recipientInput.touched || recipientInput.isError) {
      return;
    }

    recipientsInputs.push(recipientInput.value);
    recipientInput.reset();
  }

  const getButtonText = () => {
    if(!!defaultSMS) {
      return formState.loading ? "Updating..." : "Update"
    } else {
      return formState.loading ? "Creating..." : "Create"
    }
  }

  return (
    <Card className={clsx(classes.root)}>
      <CardContent>
        <form className={classes.form} onSubmit={handleUpdate}>
          <Typography className={classes.title} variant="h2">
            SMS Recipients
          </Typography>
          <TextField
            className={classes.textField}
            label="Message"
            name="message"
            type="text"
            variant="outlined"
            fullWidth
            { ...messageInput.bind }
          />
          {recipientsInputs.value.length > 0 &&
            <DeletableChips recipientsInput={recipientsInputs} />
          }
          <TextField
            className={classes.textField}
            label="Add Phone"
            name="addphone"
            type="text"
            variant="outlined"
            fullWidth
            { ...recipientInput.bind }
            InputProps={{
              endAdornment: <AddCircleIcon
                onClick={handleRecipientInput}
                className={
                  (recipientInput.touched && !recipientInput.isError) ?
                    classes.addEmailButtonActive :
                    classes.addEmailButtonInActive
                }
              />
            }}
          />
          <Button
            className={classes.createButton}
            color="primary"
            disabled={!formState.valid || formState.loading}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            {getButtonText()}
          </Button>
        </form>
      </CardContent>
    </Card>
  )
}

interface TriggerRecipientsParams {
  orgId: string;
  deviceId: string;
  signalId: string;
  triggerId: string;
}

export default () => {
  const { orgId, deviceId, signalId, triggerId } = useParams<TriggerRecipientsParams>();
  const classes = useStyles();

  const [device, setDevice] = useState<Device>(undefined as unknown as Device);

  useEffect(() => {
    getDevice(orgId, deviceId).then(setDevice);
  }, []);

  if(!device) {
    return <LoadingView />
  }

  const { email, sms } = getTriggerRecipientsConfig(device, signalId, triggerId);

  return (
    <div className={classes.root}>
      <BackButton />
      <EmailRecipientCard
        orgId={orgId} deviceId={deviceId} signalId={signalId} triggerId={triggerId} defaultEmail={email}
      />
      <SMSRecipientCard
        orgId={orgId} deviceId={deviceId} signalId={signalId} triggerId={triggerId} defaultSMS={sms}
      />
    </div>
  );
};
