import { Device, State, UserData } from "types";

/*
Selectors
*/

export const getUserData = (state:State):UserData => state.appData.user;
export const selectDevices = (state:State):Array<Device> => state.devices;
export const isLoggedIn = (state:State) => !!state.appData.user;
export const getOrgId = (state:State) => getUserData(state).orgId;
export const getGroups = (state:State) => getUserData(state).groups || [];
export const isOrbAdmin = (state:State) => getGroups(state).indexOf('orb-admin') !== -1;
export const selectDevice = (state:State, deviceId: string):Device|undefined => state.devices.find(e => e.id === deviceId);

export const selectAdminUsers = (state:State):Array<UserData> => state.admin.users;
export const selectAdminDevices = (state:State):Array<Device> => state.admin.devices;

export const selector = (state:State) => {
  return {
    selectOrgId: () => getOrgId(state),
    selectDevices: () => selectDevices(state),
    selectDevice: (deviceId: string) => selectDevice(state, deviceId),
    isLoggedIn: () => isLoggedIn(state),
    isOrbAdmin: () => isOrbAdmin(state)
  }
};

export const adminSelector = (state:State) => {
  return {
    selectUsers: () => selectAdminUsers(state),
    selectDevices: () => selectAdminDevices(state),
  }
};



//       case 'MERGE_SINGLE_TRIGGERS': {
//         const existing = data[payload.deviceId] || {};
//         const triggers = existing.data || [];
//
//         data[payload.deviceId] = {
//           data: [...triggers.filter(e => e.id !== payload.id), payload],
//           loadState: LOAD_STATES.LOADED,
//           error: null
//         };
//
//         return {
//           ...state,
//           ...deviceTriggersLoader
//         }
//       }

// export const selectTriggers = (state:State, deviceId:string) => {
//   return getTriggers(state)[deviceId] || [];
// };

// export const selectTrigger = (state:State, deviceId:string, triggersId:string):Trigger|undefined => {
//   const triggers = selectTriggers(state, deviceId);
//   for(let i=0; i<triggers.length; i++) {
//     if(triggers[i].id === triggersId) {
//       return triggers[i];
//     }
//   }
//
//   return undefined;
// };

// export const selectAllTriggers = (state:State):Array<Trigger> => {
//   return Object.values(getTriggers(state))
//     .reduce((accum, triggers) => [...accum, ...triggers], []);
// };
//
// export const selectTriggersWithTickets = (state:State) => {
//   return selectAllTriggers(state).filter(a => !!a.ticketId);
// };

const setUserData = (state:State, userData:UserData):State => {
  console.log(userData)
  userData = userData || {};
  return {
    ...state,
    appData: {
      ...state.appData,
      user: userData
    }
  };
};

const setDevices = (state:State, devices:Array<Device>):State => {
  devices = devices || [];

  return {
    ...state,
    devices: [...devices]
  };
};

export const isAppLoading = (state:State):boolean => {
  return state.appLoading;
};

const getDevices = (state:State):Array<any> => {
  return state.devices;
};

const insertDevice = (state: State, device: Device): State => {
  return {
    ...state,
    devices: [...getDevices(state), device]
  };
};

const setAppLoading = (state: State, appLoading:boolean): State => {
  return {
    ...state,
    appLoading
  };
};

export const withState = (state: State) => {
  return {
    setAppLoading: (appLoading: boolean) => withState(setAppLoading(state, appLoading)),
    insertDevice: (device:any) => withState(insertDevice(state, device)),
    setDevices: (devices:Array<Device>) => withState(setDevices(state, devices)),
    setUserData: (userData:UserData) => withState(setUserData(state, userData)),
    toState: () => state
  };
};
