import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { AlertEdit, AlertDetails } from './components';
import { BackButton, Spinner } from "components";
import { Context } from "store";
import { getOrgId } from "store/coreData";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Alert = props => {
  const { history } = props;
  const classes = useStyles();
  const { state, dispatch } = useContext(Context);

  const [editMode, setEditMode] = useState(false);

  const { deviceId, alertId } = useParams();

  const org = getOrgId(state);

  const handleBack = () => {
    if (history.length === 2) {
      history.replace('/devices');
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    // TODO
    // CoreData.DeviceAlertsLoader.load(state, dispatch, { org, deviceId });
  }, [deviceId]);

  // const alert = selectAlert(state, deviceId, alertId);
  const alert = undefined; //t = selectTrigger(state, deviceId, alertId);

  if(!alert) {
    return <Spinner />;
  }

  return (
    <div className={classes.root}>
      <BackButton onBack={handleBack} />
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
        >
          {editMode ?
            <AlertEdit alert={alert} setEditMode={setEditMode} /> :
            <AlertDetails alert={alert} setEditMode={setEditMode} />
          }
        </Grid>
      </Grid>
    </div>
  );
};

export default Alert;
