import { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Context } from 'store'
import { getDevices } from 'store/api';
import DevicesToolbar from './DevicesToolbar';
import DevicesTable from './DevicesTable';

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const DeviceList = () => {
  const classes = useStyles();
  const { state, mutator } = useContext(Context);

  useEffect(() => {
    const orgId = state.user?.orgId as string;

    getDevices(orgId).then(mutator.setDevices);
  }, []);

  const devices = state.devices ?? [];

  return (
    <div className={classes.root}>
      <DevicesToolbar />
      <div className={classes.content}>
        <DevicesTable devices={devices} />
      </div>
    </div>
  );
};

export default DeviceList;
