import {useHistory, useParams} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  TextField,
  Typography,
  FormControl, InputLabel, Card, CardContent
} from '@material-ui/core';
import {useFormState, useInputRequired, useSelectRequired} from 'hooks';
import BackButton from "components/BackButton";
import Select from '@material-ui/core/Select';
import {ApiError, createTrigger} from "../../store/api";
import clsx from "clsx";

const useStyles = makeStyles((theme:any) => ({
  root: {
    padding: theme.spacing(3)
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  form: {
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    width: 300
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  createButton: {
    margin: theme.spacing(2, 0)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface CreateTriggerParams {
  orgId: string;
  deviceId: string;
  signalId: string;
}

const CreateTrigger = () => {
  const history = useHistory();

  const { orgId, deviceId, signalId } = useParams<CreateTriggerParams>();

  const classes = useStyles();

  const countInput = useInputRequired('');
  const thresholdInput = useInputRequired('');
  const periodInput = useInputRequired('1');
  const comparatorInput = useSelectRequired('>');
  const formState = useFormState(countInput, thresholdInput, periodInput, comparatorInput);

  const handleCreateDevice = (event:any) => {
    event.preventDefault();

    formState.setLoading();

    createTrigger({
      orgId, deviceId, signalId,
      count: +countInput.value,
      threshold: +thresholdInput.value,
      period: +periodInput.value,
      comparator: comparatorInput.value
    }).then(() => {
      history.push(`/admin/org/${orgId}/devices/${deviceId}`);
    }).catch((error:ApiError) => {
      formState.setError(error.message);
    });
  };

  return (
    <div className={classes.root}>
      <BackButton />
      <Card className={clsx(classes.root)}>
        <CardContent>
          <form className={classes.form} onSubmit={handleCreateDevice}>
            <Typography className={classes.title} variant="h2">
              Create Trigger
            </Typography>
            <TextField
              className={classes.textField}
              label="Count"
              name="count"
              type="number"
              variant="outlined"
              fullWidth
              { ...countInput.bind }
            />
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">Comparator</InputLabel>
              <Select
                native
                label="Comparator"
                inputProps={{
                  name: 'Comparator',
                  id: 'outlined-comp-native-simple',
                }}
                { ...comparatorInput.bind }
              >
                <option value={">="}>{">="}</option>
                <option value={">"}>{">"}</option>
                <option value={"="}>{"="}</option>
                <option value={"<"}>{"<"}</option>
                <option value={"<="}>{"<="}</option>
              </Select>
            </FormControl>
            <TextField
              className={classes.textField}
              label="Threshold"
              name="threshold"
              type="number"
              variant="outlined"
              fullWidth
              { ...thresholdInput.bind }
            />
            <TextField
              className={classes.textField}
              label="Period"
              name="period"
              type="number"
              variant="outlined"
              fullWidth
              { ...periodInput.bind }
            />
            <Button
              className={classes.createButton}
              color="primary"
              disabled={!formState.valid || formState.loading}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {formState.loading ? "Creating..." : "Create"}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default CreateTrigger;
