import { useState } from 'react';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from '@material-ui/core';
import { Device } from "types";

const useStyles = makeStyles((theme: any) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

interface DevicesTableProps {
  className?: string;
  devices: Device[];
}

const DevicesTable = (props: DevicesTableProps) => {
  const { className, devices, ...rest } = props;
  const history = useHistory();
  const classes: any = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event: any, page: any) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(event.target.value);
  };

  const handleClick = (event: any, deviceId: string) => {
    history.push(`/device/${deviceId}`);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Last Modified</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {devices.slice(0, rowsPerPage).map((device: Device) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={device.id}
                    onClick={(event) => handleClick(event, device.id)}
                  >
                    <TableCell>{device.id}</TableCell>
                    <TableCell>
                      {moment(device.created).format('MM/DD/YYYY')}
                    </TableCell>
                    <TableCell>
                      {moment(device.modified).format('MM/DD/YYYY')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={devices.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default DevicesTable;
