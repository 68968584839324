import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Org } from 'types';
import { getOrgs } from 'store/api';
import moment from "moment/moment";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme:any) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const OrgsToolbar = (props:any) => {
  const history = useHistory();

  const classes = useStyles();

  const handleCreateOrgClick = (event:any) => {
    history.push(`/admin/org/create`);
  };

  return (
    <div className={classes.root}>
      <Button color="primary" variant="contained" onClick={handleCreateOrgClick}>
        Create Org
      </Button>
    </div>
  );
};

interface OrgsTableProps {
  orgs:Array<Org>;
}

const OrgsTable = (props:OrgsTableProps) => {
  const { orgs } = props;
  const history = useHistory();

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handlePageChange = (event:any, page:any) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event:any) => {
    setRowsPerPage(event.target.value);
  };

  const handleClick = (event:any, orgId:string) => {
    history.push(`/admin/org/${orgId}`);
  };

  return (
    <Card>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Last Modified</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orgs.slice(0, rowsPerPage).map(org => (
                <TableRow
                  hover
                  key={org.id}
                  onClick={(event) => handleClick(event, org.id)}
                >
                  <TableCell>{org.id}</TableCell>
                  <TableCell>{org.name}</TableCell>
                  <TableCell>
                    {moment(org.created).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell>
                    {moment(org.modified).format('MM/DD/YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={orgs.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

const OrgsList = () => {
  const classes = useStyles();
  const [orgs, setOrgs] = useState<Array<Org>>([]);

  useEffect(() => {
    getOrgs().then(setOrgs);
  }, []);

  return (
    <div className={classes.root}>
      <OrgsToolbar />
      <div className={classes.content}>
        <OrgsTable orgs={orgs} />
      </div>
    </div>
  );
};

export default OrgsList;
