import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import { useFormState, useInputRequired, useInputEmail, useInputPassword } from 'hooks';
import BackButton from "components/BackButton";

const useStyles = makeStyles((theme:any) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  createButton: {
    margin: theme.spacing(2, 0)
  }
}));

const CreateUser = () => {
  // const { dispatch } = useContext(Context);
  // const history = useHistory();
  // @ts-ignore
  const { orgId } = useParams();

  const classes = useStyles();

  const nameInput = useInputRequired('');
  const emailInput = useInputEmail('');
  const passwordInput = useInputPassword('');
  const formState = useFormState(nameInput, emailInput, passwordInput);

  // TODO
  // const groups:Array<string> = [];

  const handleCreateOrg = (event:any) => {
    event.preventDefault();

    formState.setLoading();

    // createUser(orgId, nameInput.value, emailInput.value, groups, passwordInput.value)
    //   .then(user => {
    //     dispatch({ type: 'UserCreated', payload: { user } });
    //     history.replace(`/orgs/${orgId}/users/${user.id}`);
    //   }).catch((error:ApiError) => {
    //     formState.setError(error.message);
    //   });
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <BackButton />
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleCreateOrg}>
                <Typography className={classes.title} variant="h2">
                  Create User
                </Typography>
                <TextField
                  className={classes.textField} fullWidth disabled
                  label="Organization" name="org" type="text" variant="outlined" value={orgId}
                />
                <TextField
                  className={classes.textField} fullWidth
                  label="Name" name="name" type="text" variant="outlined"
                  { ...nameInput.bind }
                />
                <TextField
                  className={classes.textField} fullWidth
                  label="Email" name="email" type="text" variant="outlined"
                  { ...emailInput.bind }
                />
                <TextField
                  className={classes.textField} fullWidth
                  label="Password" name="password" type="password" variant="outlined"
                  { ...passwordInput.bind }
                />
                <Button
                  className={classes.createButton}
                  color="primary"
                  disabled={!formState.valid || formState.loading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {formState.loading ? "Creating..." : "Create"}
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateUser;
