import axios, { AxiosError } from "axios";
import { Trigger, Signal, Device, UserData, Org, AppData } from "types";

export class ApiError extends Error {
  constructor(message:string) {
    super(message);
    this.name = this.constructor.name;
  }
}

const transformError = (err:AxiosError):ApiError => {
  if(err.response && err.response.data && err.response.data.error) {
    throw new ApiError(err.response.data.error);
  }

  if(err.response && err.response.status === 404) {
    throw new ApiError("Not Found");
  }

  if(err.response && err.response.status === 401) {
    throw new ApiError("Unauthorized");
  }

  throw new ApiError("Unknown Error");
};

export const getAppInitData = async (): Promise<AppData> => {
  return axios.get('/api/app/init')
    .then(response => response.data)
    .catch(transformError);
};

export const login = (email:string, password:string): Promise<{ user:UserData, devices:Array<Device> }> => {
  return axios.post('/api/login', {
    email, password
  })
    .then(response => response.data)
    .catch(transformError);
};

export const logout = ():Promise<void|ApiError> => {
  return axios.get(`/api/logout`)
    .then(() => {})
    .catch(transformError);
};

export const getDevices = async (orgId:string):Promise<Array<Device>> => {
  return axios.post(`/api/devices/list`, { orgId })
    .then(response => response.data.devices)
    .catch(transformError);
};

export const getDevice = (orgId:string, deviceId:string):Promise<Device> => {
  return axios.post(`/api/devices/get`, { orgId, id: deviceId})
    .then(response => response.data.device)
    .catch(transformError);
};

export const getTriggers = (org:string, deviceId:string):Promise<Array<Trigger>> => {
  throw new Error('Unimplemented');
  // return axios.get(`/org/${org}/triggers/${deviceId}`)
  //   .then(response => response.data.triggers)
  //   .catch(transformError);
};

export const updateTrigger = (org:string, deviceId:string, triggersId:string, triggersData:any) => {
  throw new Error('Unimplemented');
  // return axios.put(`/org/${org}/triggers/${deviceId}/${triggersId}`, triggersData);
};

interface CreateTriggerParams {
  orgId: string;
  deviceId: string;
  signalId: string;
  count: number;
  period: number;
  threshold: number;
  comparator: string;
}

export const createTrigger = (params: CreateTriggerParams) => {
  return axios.post(`/api/triggers/create`, params)
    .then(response => response.data.device)
    .catch(transformError);
}

interface UpdateTriggerEmailParams {
  orgId: string;
  deviceId: string;
  signalId: string;
  triggerId: string;
  recipients: string[];
  subject?: string;
  body?: string;
}

export const updateTriggerEmailConfig = (params: UpdateTriggerEmailParams) => {
  return axios.post(`/api/triggers/update/email`, params)
    .then(response => response.data.device)
    .catch(transformError);
}

interface UpdateTriggerSmsParams {
  orgId: string;
  deviceId: string;
  signalId: string;
  triggerId: string;
  recipients: string[];
  message?: string;
}

export const updateTriggerSmsConfig = async (params: UpdateTriggerSmsParams) => {
  return axios.post(`/api/triggers/update/sms`, params)
    .then(response => response.data.device)
    .catch(transformError);
}

export const createDevice = (org:string, name:string, signalTypes:Array<string>): Promise<{ device: Device, signals: Array<Signal>}> => {
  throw new Error('Unimplemented');
  // return axios.post(`/org/${org}/devices`, {
  //   name, signalTypes
  // })
  //   .then(response => response.data)
  //   .catch(transformError);
};

//   async updateTriggers(state, dispatch, { org, deviceId, triggersId, refresh }, triggersData) {
//     await axios.put(`/org/${org}/triggers/${deviceId}/${triggersId}`, triggersData);
//     return this.load(state, dispatch, { org, deviceId, refresh });
//   };

export const getOrgs = ():Promise<Array<Org>> => {
  return axios.post(`/api/orgs/list`)
    .then(response => response.data.organizations)
    .catch(transformError);
};

export const createOrg = (name:string): Promise<Org> => {
  throw new Error('Unimplemented');
  // return axios.post(`/org`, {
  //   name
  // })
  //   .then(response => response.data.org)
  //   .catch(transformError);
};

export const getOrg = (orgId:string): Promise<Org> => {
  return axios.post(`/api/orgs/get`, {
    id: orgId
  })
    .then(response => response.data.organization)
    .catch(transformError);
};

export const getUsers = (orgId:string):Promise<Array<UserData>> => {
  return axios.post(`/api/users/list`, {
    orgId
  })
    .then(response => response.data.users)
    .catch(transformError);
};

export const createUser = (orgId:string, name:string, email:string, groups:Array<string>, password:string): Promise<UserData> => {
  throw new Error('Unimplemented');
  // return axios.post(`/org/${orgId}/users`, {
  //   name
  // })
  //   .then(response => response.data.user)
  //   .catch(transformError);
};

interface AckTriggerTicketParams {
  orgId: string;
  deviceId: string;
  signalId: string;
  triggerId: string;
  ticketId: number;
}

export const ackTriggerTicket = (params: AckTriggerTicketParams):Promise<any> => {
  return axios.post(`/api/tickets/ack`, params)
    .catch(transformError);
};

interface CloseTriggerTicketParams {
  orgId: string;
  deviceId: string;
  signalId: string;
  triggerId: string;
  ticketId: number;
}

export const closeTriggerTicket = (params: CloseTriggerTicketParams):Promise<any> => {
  return axios.post(`/api/tickets/close`, params)
    .catch(transformError);
};
